import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f25db16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sale-overview-retail" }
const _hoisted_2 = { class: "sale-overview-retail" }
const _hoisted_3 = { class: "sale-overview__checking" }
const _hoisted_4 = { class: "sale-overview__checking--child" }
const _hoisted_5 = { class: "sale-overview__checking--child" }
const _hoisted_6 = { class: "sale-overview-retail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Retail = _resolveComponent("Retail")!
  const _component_Sales = _resolveComponent("Sales")!
  const _component_ClaimChecking = _resolveComponent("ClaimChecking")!
  const _component_PayoutDealer = _resolveComponent("PayoutDealer")!
  const _component_IncentiveClaim = _resolveComponent("IncentiveClaim")!
  const _component_FailedReason = _resolveComponent("FailedReason")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumb, { dataSource: [] }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Retail, { ref: "dom1" }, null, 512)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Sales, { ref: "dom2" }, null, 512)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ClaimChecking, { ref: "dom3" }, null, 512)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_PayoutDealer, { ref: "dom4" }, null, 512)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_IncentiveClaim, { ref: "dom5" }, null, 512)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_FailedReason, { ref: "dom6" }, null, 512)
    ])
  ], 64))
}