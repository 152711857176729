
import { queryHistogram, getRVTable } from '@/API/salesReporting';
import { defineComponent, ref, reactive, toRefs, nextTick } from 'vue';
import * as echarts from 'echarts';
import moment from 'moment';
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
export default defineComponent({
  setup() {
    const activeKey = ref('1');
    const router = useRouter();
    let myChart: any = null;
    const myChartDom = ref<any>(null);
    const buList = ref<any[]>([]);
    const brandList = ref<any[]>([]);
    const brandArray = ref<any[]>([]);
    const searchData = reactive({
      bu: '',
      brand: '',
      year: '',
      quarter: [`${moment().quarter()}`]
    });
    const total: any[] = [];
    const yearArray: Array<string> = [];
    for (let i = 5; i >= 0; i--) {
      yearArray.push(moment().subtract(i, 'year').format('YYYY'));
    }
    for (let i = 1; i <= 5; i++) {
      yearArray.push(moment().add(i, 'year').format('YYYY'));
    }

    const option = ref<any>({
      grid: {
        left: '0%',
        right: '0%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        axisTick: {
          show: false
        }
      },
      yAxis: [
        {
          type: 'value',
          show: false
        },
        {
          type: 'value',
          show: false,
          min: 0,
          max: 100
        }
      ],
      legend: {
        x: 'left'
      },
      tooltip: {
        trigger: 'axis'
      },
      label: {
        show: true,
        position: 'top'
      },
      series: [
        {
          name: 'Ineligible Retail No.',
          data: [],
          type: 'bar',
          zlevel: 1,
          barWidth: 20,
          color: '#00ADEF',
          label: {
            color: '#0088C6'
          }
        },
        {
          name: 'Eligible Retail No.',
          data: [],
          type: 'bar',
          barGap: '-100%',
          barWidth: 20,
          color: '#7FD6F7',
          label: {
            offset: [0, -15],
            color: '#7FD6F7',
            formatter: (params: any) => {
              return total[params.dataIndex];
            }
          }
        },
        {
          name: 'Eligible Retail Passing Rate',
          data: [],
          type: 'line',
          yAxisIndex: 1,
          color: '#FFBA3E',
          label: {
            color: '#FFBA3E',
            formatter: '{c}%',
            rotate: 30,
            fontSize: 10
          }
        }
      ]
    });

    const dataSource = ref<any>([]);

    const buName = ref('');

    const columns = ref<any[]>([
      {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        width: '100px'
      },
      {
        title: 'Eligible Retail No.',
        dataIndex: 'eligibleRetailNo',
        key: 'eligibleRetailNo',
        width: '200px'
      },
      {
        title: 'Submited Retail No.',
        dataIndex: 'submitedRetailNo',
        key: 'submitedRetailNo',
        width: '200px'
      },
      {
        title: 'Actual Retail No.',
        dataIndex: 'actualRetailNo',
        key: 'actualRetailNo',
        width: '200px'
      },
      {
        title: 'Eligible RetailPassing Rate%',
        dataIndex: 'eligibleRetailPassingRate',
        key: 'eligibleRetailPassingRate',
        width: '200px'
      },
      {
        title: 'RetailSubmission Rate%',
        dataIndex: 'retailSubmissionRate',
        key: 'retailSubmissionRate',
        width: '200px'
      }
    ]);

    const generateParams = () => {
      const QUARTER_MONTH_MAP = {
        1: ['01', '02', '03'],
        2: ['04', '05', '06'],
        3: ['07', '08', '09'],
        4: ['10', '11', '12']
      };
      const monthList = searchData.quarter
        .map((qur: any) => {
          return QUARTER_MONTH_MAP[qur];
        })
        .flat();
      return {
        bu: buName.value,
        buId: searchData.bu,
        brand: searchData.brand,
        year: searchData.year,
        quarter: searchData.quarter,
        month: monthList
      };
    };

    const queryData = () => {
      if (searchData.bu && searchData.year) {
        for (let i = 0; i < buList.value.length; i++) {
          if (buList.value[i].id === searchData.bu) {
            buName.value = buList.value[i].nameEn;
          }
        }
        const params = generateParams();
        total.length = 0;
        queryHistogram(params).then((res) => {
          const eligibleRetail: any[] = [];
          const eligibleRetailPassingRate: any[] = [];
          const ineligibleRetail: any[] = [];
          for (let i = 0; i < res.length; i++) {
            total.push(res[i].total);
            const eligibleRetailNo = res[i].eligibleRetailNo;
            const ineligible = res[i].ineligible;
            const det = eligibleRetailNo - ineligible;
            const MAX_GAP = 50;
            let offsetEligible = [0, 0];
            const offsetIneligible = [0, 0];
            if (Math.abs(det) < MAX_GAP) {
              if (det > 0) {
                offsetEligible = [0, -MAX_GAP * 0.5 + MAX_GAP * (det / MAX_GAP) ** 2];
              } else {
                offsetEligible = [0, -MAX_GAP * 0.5 - MAX_GAP * (det / MAX_GAP) ** 2];
              }
            }
            eligibleRetail.push({
              value: eligibleRetailNo,
              label: { offset: offsetEligible }
            });
            ineligibleRetail.push({
              value: ineligible,
              label: { offset: offsetIneligible }
            });
            eligibleRetailPassingRate.push(res[i].retailRate === 'N/A' ? 'N/A' : res[i].retailRate.replace('%', ''));
          }
          option.value.series[0].data = ineligibleRetail;
          option.value.series[1].data = eligibleRetail;
          option.value.series[2].data = eligibleRetailPassingRate;

          nextTick(() => {
            myChart.setOption(option.value);
          });
        });
      } else {
        message.error('Year and bu must be select.');
      }
    };

    const queryTableByCondition = () => {
      if (searchData.bu && searchData.year && searchData.quarter.length > 0) {
        let url = '';

        const params = generateParams();

        switch (activeKey.value) {
          case '1':
            url = '/report/query/submission/byRegion';
            columns.value[0] = {
              title: 'Region',
              dataIndex: 'region',
              key: 'region',
              width: '100px'
            };
            break;
          case '2':
            url = '/report/query/submission/bySubRegion';
            columns.value[0] = {
              title: 'Sub Region',
              dataIndex: 'subRegion',
              key: 'subRegion',
              width: '100px'
            };
            break;
          case '3':
            url = '/report/query/submission/byDealerGroup';
            columns.value[0] = {
              title: 'Dealer Group',
              dataIndex: 'dealerGroup',
              key: 'dealerGroup',
              width: '200px'
            };
            break;
          case '4':
            url = '/report/query/submission/byDealer';
            columns.value[0] = {
              title: 'Dealer',
              dataIndex: 'dealerNameEn',
              key: 'dealerNameEn',
              width: '200px'
            };
            (params as any).sort = -1;
            break;
        }

        getRVTable(params, url).then((res) => {
          dataSource.value = res;
        });
      }
    };

    const changeTabs = (index: string) => {
      activeKey.value = index;
      queryTableByCondition();
    };

    const searchAll = () => {
      if (searchData.quarter.length === 0) {
        message.warning('Please select query criteria');
        return;
      }
      queryData();
      queryTableByCondition();
    };

    const getBrand = () => {
      searchData.brand = '';
      for (let i = 0; i < brandArray.value.length; i++) {
        if (searchData.bu === brandArray.value[i].buId) {
          brandList.value = JSON.parse(JSON.stringify(brandArray.value[i].brandList));
        }
      }
      if (brandList.value.length) {
        brandList.value.push({ id: 0, brandNameEn: 'All' });
        searchData.brand = 'All';
      }
    };

    let first = true;
    const init = (buArray: Array<string>, brandArrayData: Array<any>) => {
      buList.value = buArray
        .filter((bu: any) => {
          return bu.parentId === null;
        })
        .filter((bu: any) => {
          return bu.nameEn !== 'CPO' && bu.nameEn !== 'Companycar';
        });
      brandArray.value = brandArrayData;
      searchData.bu = (buList.value[0] as any).id;
      for (let i = 0; i < buArray.length; i++) {
        if ((buArray[i] as any).nameEn === 'MB') {
          searchData.bu = (buArray[i] as any).id;
        }
      }
      getBrand();

      searchData.year = moment().format('YYYY');
      searchData.quarter = [`${moment().subtract(1, 'quarter').quarter()}`];
      if (first) {
        myChart = echarts.init(myChartDom.value);
        first = false;
      }

      searchAll();
    };

    const gotoDetail = () => {
      router.push({
        path: '/salesreporting/RVSubmissionOEM',
        query: {
          bu: searchData.bu,
          brand: searchData.brand,
          year: searchData.year,
          quarter: searchData.quarter
        }
      });
    };

    //period初始化
    const openProgramPeriodYear = ref<boolean>(false);
    const handlerOpenProgramPeriodChange = (status: any): void => {
      openProgramPeriodYear.value = status;
    };
    const handlerPanelProgramPeriodChange = (value: string): void => {
      const time = moment(value).format('YYYY');
      searchData.year = time as string;
      openProgramPeriodYear.value = false;
    };

    const sortType = ref('descent');
    const sortData = (type: string) => {
      const params = generateParams();
      if (type === 'descent') {
        (params as any).sort = -1;
      } else {
        (params as any).sort = 1;
      }
      const url = '/report/query/submission/byDealer';
      getRVTable(params, url).then((res) => {
        dataSource.value = res;
        sortType.value = type;
      });
    };
    return {
      openProgramPeriodYear,
      handlerOpenProgramPeriodChange,
      handlerPanelProgramPeriodChange,
      myChartDom,
      searchAll,
      gotoDetail,
      activeKey,
      brandArray,
      changeTabs,
      myChart,
      dataSource,
      columns,
      buList,
      brandList,
      yearArray,
      ...toRefs(searchData),
      init,
      sortType,
      sortData,
      getBrand
    };
  }
});
