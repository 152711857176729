
import { message } from 'ant-design-vue';
import { defineComponent, ref, onMounted, reactive, toRefs } from 'vue';
import { getIncentiveAll } from '@/API/salesReporting';
import { getFindByGroupCode } from '@/API/checking/SICCOCheckingDetail';
import moment from 'moment';
import { Moment } from 'moment';
import * as echarts from 'echarts';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const router = useRouter();
    const myChart = ref<any>();
    const myChartDom = ref<any>(null);
    const buList = ref<any[]>([]);
    const brandList = ref<any[]>([]);
    const brandArray = ref<any[]>([]);
    const searchData = reactive({
      bu: '',
      brand: '',
      year: '',
      quarter: ['1'],
      cOption: ''
    });
    const optionList = ref<any[]>([]);

    const dataSource = ref<any[]>([]);
    const columns = [
      {
        title: 'Chaim Checking Overview',
        dataIndex: 'checkResultMessage',
        key: 'checkResultMessage',
        align: 'left',
        width: 200
      },
      {
        title: 'Dealer Confirmation Status',
        children: [
          {
            title: 'Dealer Confirmed',
            dataIndex: 'accepted',
            key: 'accepted',
            align: 'center'
          },
          {
            title: 'Dealer Rejected',
            dataIndex: 'rejected',
            key: 'rejected',
            align: 'center'
          },
          {
            title: 'Dealer Un-confirmed',
            dataIndex: 'communicated',
            key: 'communicated',
            align: 'center'
          }
        ]
      }
    ];

    const queryData = () => {
      if (!searchData.bu || !searchData.year) {
        message.error('Year and BU must be selected!');
        return;
      }
      const brandA = [];
      if (searchData.brand === 'All') {
        for (let i = 0; i < brandList.value.length; i++) {
          if (brandList.value[i].brandNameEn != 'All') {
            brandA.push(brandList.value[i].brandNameEn);
          }
        }
      } else {
        brandA.push(searchData.brand);
      }

      const yearAndQuarter = searchData.quarter.map((quarter: string) => {
        return searchData.year + '-' + quarter;
      });

      const unwarp = (obj: any) => obj && (obj.__v_raw || obj.valueOf() || obj);

      getIncentiveAll({
        option: searchData.cOption,
        buId: searchData.bu,
        brandName: brandA,
        yearAndQuarter
      }).then((res) => {
        unwarp(myChart.value).setOption({ ...res.option, grid: { left: '15%' } });
        const data = res.table;
        console.log('res', res);
        for (let i = 0; i < data.length; i++) {
          data[i].checkResultMessage = data[i].checkResultEn + ': ' + data[i].total;
          data[i].accepted = data[i].accepted === '' ? '-' : data[i].accepted;
          data[i].communicated = data[i].communicated === '' ? '-' : data[i].communicated;
          data[i].rejected = data[i].rejected === '' ? '-' : data[i].rejected;
        }
        dataSource.value = data;
      });
    };
    const yearArray: Array<string> = [];
    for (let i = 5; i >= 0; i--) {
      yearArray.push(moment().subtract(i, 'year').format('YYYY'));
    }
    for (let i = 1; i <= 5; i++) {
      yearArray.push(moment().add(i, 'year').format('YYYY'));
    }

    const getBrand = () => {
      searchData.brand = '';
      brandList.value = [];
      for (let i = 0; i < brandArray.value.length; i++) {
        if (searchData.bu === brandArray.value[i].buId) {
          brandList.value = JSON.parse(JSON.stringify(brandArray.value[i].brandList));
        }
      }
      if (brandList.value.length) {
        brandList.value.push({
          brandNameCn: 'All',
          brandNameEn: 'All',
          id: -1
        });
        searchData.brand = 'All';
      }
    };

    const init = (buArray: Array<string>, brandArrayData: Array<any>) => {
      buList.value = buArray;
      brandArray.value = brandArrayData;
      searchData.bu = (buArray[0] as any).id;
      for (let i = 0; i < buArray.length; i++) {
        if ((buArray[i] as any).nameEn === 'MB') {
          searchData.bu = (buArray[i] as any).id;
        }
      }

      getBrand();

      searchData.year = moment().format('YYYY');
      searchData.quarter = [moment().subtract(1, 'quarter').quarter() + ''];
      myChart.value = echarts.init(myChartDom.value);

      getFindByGroupCode({
        groupCode: 'overview_of_Incentive_option'
      }).then((res: any) => {
        for (let i = 0; i < res.length; i++) {
          optionList.value.push({
            key: res[i].itemCode,
            value: res[i].itemNameEn
          });
        }
        searchData.cOption = optionList.value[0].key;
        queryData();
      });
    };

    const tableHeight = ref(180);

    const gotoDetail = () => {
      let quarter = '';
      for (let i = 0; i < searchData.quarter.length; i++) {
        quarter = quarter + ('Q' + searchData.quarter[i]) + ',';
      }
      router.push({
        path: '/salesreporting/claimCheckingReportOEM',
        query: {
          bu: searchData.bu,
          brand: searchData.brand,
          year: searchData.year,
          quarter: quarter.substring(0, quarter.length - 1),
          isClear: 'true'
        }
      });
    };

    //period初始化
    const openProgramPeriodYear = ref<boolean>(false);
    const handlerOpenProgramPeriodChange = (status: any): void => {
      openProgramPeriodYear.value = status;
    };
    const handlerPanelProgramPeriodChange = (value: string): void => {
      const time = moment(value).format('YYYY');
      searchData.year = time as string;
      openProgramPeriodYear.value = false;
    };

    return {
      optionList,
      openProgramPeriodYear,
      handlerOpenProgramPeriodChange,
      handlerPanelProgramPeriodChange,
      init,
      brandList,
      buList,
      yearArray,
      gotoDetail,
      myChartDom,
      getBrand,
      myChart,
      dataSource,
      columns,
      tableHeight,
      ...toRefs(searchData),
      queryData
    };
  }
});
