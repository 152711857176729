
import { defineComponent, reactive, toRefs, ref } from 'vue';
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import moment from 'moment';
import { getSalesIncentiveOverview } from '@/API/salesReporting';

export default defineComponent({
    setup() {
        const router = useRouter();
        const buList = ref<any[]>([]);
        const brandList = ref<any[]>([]);
        const brandArray = ref<any[]>([]);
        const searchData = reactive({
            bu: '',
            brand: '',
            year: '',
        });

        const programNum = ref(0);
        const programSummaryDataSource = ref<any[]>([]);
        const programSummaryColumns = [
            {
                title: '',
                dataIndex: 'signal',
            },
            {
                title: 'New',
                dataIndex: 'newNum',
                key: 'newNum',
            },
            {
                title: 'Checking',
                dataIndex: 'checkingNum',
                key: 'checkingNum',
            },
            {
                title: 'Closed',
                dataIndex: 'closeNum',
                key: 'closeNum',
            },
        ];

        const payoutSummaryDataSource = ref<any[]>([]);
        const payoutSummaryColumns = [
            {
                title: 'Payout',
                // dataIndex: 'payoutVal',
                key: 'payoutVal',
                slots: { customRender: 'payoutVal' },
            },
            {
                title: 'To-be Paid',
                dataIndex: 'toBePaidVal',
                key: 'toBePaidVal',
            },
            {
                title: 'Program Budget',
                // dataIndex: 'programBudgetVal',
                key: 'programBudgetVal',
                slots: { customRender: 'programBudgetVal' },
            },
        ];

        const queryData = () => {
            if (!searchData.bu || !searchData.year) {
                message.error('Year and BU must be selected!');
                return;
            }
            const params = {
                buId: searchData.bu,
                brandName: searchData.brand === 'All' ? '' : searchData.brand,
                year: searchData.year,
            };
            getSalesIncentiveOverview(params).then((res: any) => {
                programNum.value = res.programNum;
                const signal = [
                    {
                        signal: 'Vol.',
                    },
                    {
                        signal: '%',
                    },
                ];
                const programSummary = signal.map((sg: any, index: number) => {
                    return { ...sg, ...res.programSummaryList[index] };
                });
                programSummaryDataSource.value = programSummary;
                payoutSummaryDataSource.value = res.payoutSummaryList;
            });
        };

        const getBrand = () => {
            searchData.brand = '';
            for (let i = 0; i < brandArray.value.length; i++) {
                if (searchData.bu === brandArray.value[i].buId) {
                    brandList.value = JSON.parse(
                        JSON.stringify(brandArray.value[i].brandList)
                    );
                }
            }
            if (brandList.value.length) {
                brandList.value.push({ id: 0, brandNameEn: 'All' });
                searchData.brand = 'All';
            }
        };

        const init = (buArray: Array<string>, brandArrayData: Array<any>) => {
            buList.value = buArray;
            brandArray.value = brandArrayData;
            searchData.bu = (buArray[0] as any).id;
            for (let i = 0; i < buArray.length; i++) {
                if ((buArray[i] as any).nameEn === 'MB') {
                    searchData.bu = (buArray[i] as any).id;
                }
            }

            getBrand();
            searchData.year = moment().format('YYYY');

            queryData();
        };

        const openProgramPeriodYear = ref<boolean>(false);
        const handlerOpenProgramPeriodChange = (status: any): void => {
            openProgramPeriodYear.value = status;
        };
        const handlerPanelProgramPeriodChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            searchData.year = time as string;
            openProgramPeriodYear.value = false;
        };

        const gotoDetail = () => {
            router.push({
                path: '/salesreporting/claimCheckingReportOEM',
                query: {
                    bu: searchData.bu,
                    brand: searchData.brand,
                    year: searchData.year,
                    isClear: 'false',
                },
            });
        };

        return {
            init,
            programNum,
            programSummaryColumns,
            programSummaryDataSource,
            payoutSummaryDataSource,
            payoutSummaryColumns,
            ...toRefs(searchData),
            openProgramPeriodYear,
            handlerOpenProgramPeriodChange,
            handlerPanelProgramPeriodChange,
            brandList,
            buList,
            queryData,
            gotoDetail,
            getBrand,
        };
    },
});
