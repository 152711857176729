
import { getUser } from '@/API/claim/delaerClaim/delaer';
import { defineComponent, ref, onMounted, nextTick } from 'vue';
import Retail from './RetailValdationOverview/index2.vue';
import Sales from './SalesIncentiveOverview/index.vue';
import ClaimChecking from './ClaimCheckingOverview/index.vue';
import PayoutDealer from './PayoutDealer/index.vue';
import IncentiveClaim from './IncentiveClaim/index2.vue';
import FailedReason from './FailedReason/index.vue';
import { findBrandByBu } from '@/API/salesReporting';
export default defineComponent({
    components: {
        Retail,
        Sales,
        ClaimChecking,
        PayoutDealer,
        IncentiveClaim,
        FailedReason,
    },
    setup() {
        //

        const dom1 = ref<any>(null);
        const dom2 = ref<any>(null);
        const dom3 = ref<any>(null);
        const dom4 = ref<any>(null);
        const dom5 = ref<any>(null);
        const dom6 = ref<any>(null);
        const buArray = ref<any[]>([]);
        const buIDArray = ref<string[]>([]);
        const brandArray = ref<any[]>([]);
        const getBU = async () => {
            return getUser();
        };
        const getBrand = async () => {
            return findBrandByBu({ buIds: buIDArray.value });
        };

        const init = async () => {
            //
            const buList = await getBU();
            buList.buList.filter((item: any) => {
                if (item.parentId === null) {
                    buIDArray.value.push(item.id);
                    buArray.value.push(item);
                }
            });
            const brandList = await getBrand();
            // const map = new Map();
            // for (let i=0;i<brandList.length;i++){
            //     for (let j=0;j<brandList[i].brandList.length;j++){
            //         // brandArray.value.push(brandList[i].brandList[j])
            //         map.set(brandList[i].brandList[j].brandNameEn,brandList[i].brandList[j])
            //     }
            // }
            // brandArray.value = [...map.values()]
            nextTick(() => {
                dom1.value.init(buArray.value, brandList);
                dom2.value.init(buArray.value, brandList);
                dom3.value.init(buArray.value, brandList);
                dom4.value.init(buArray.value, brandList);
                dom5.value.init(buArray.value, brandList);
                dom6.value.init(buArray.value, brandList);
            });
        };

        onMounted(() => {
            init();
        });
        return {
            buIDArray,
            brandArray,
            dom1,
            dom2,
            dom3,
            dom4,
            dom5,
            dom6,
        };
    },
});
